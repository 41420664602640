import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import Marker from './marker';
import MyLocation from './myLocation';
import { fetchPlaces } from '../actions/places';
import { fetchCategories } from '../actions/categories';
import { panToUserPositionDone } from '../actions/location';
import InfoOverlay from './infoOverlay';
import Menu from './menu';
import OptionsOverlay from './optionsOverlay';
import ContentArea from './contentArea';
import ImprintLink from './imprintLink';
import PrivacyLink from './privacyLink';
import LanguageSwitch from './languageSwitch';
import Lightbox from './lightbox';

class Map extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    places: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    options: PropTypes.object.isRequired,
    filter: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      map: null,
      maps: null,
    };

    const { dispatch } = this.props;

    dispatch(fetchCategories());
    dispatch(fetchPlaces());
  }

  componentDidUpdate() {
    const { location, dispatch } = this.props;
    if (location.shouldPanToUserPosition && this.map) {
      const { lat, lng } = location.userPosition;
      this.map.panTo(new window.google.maps.LatLng(lat, lng));
      dispatch(panToUserPositionDone());
    }
  }

  render() {
    const { places, location, options, filter } = this.props;

    let center = null;
    if (location.position) {
      center = {
        lat: location.position.lat,
        lng: location.position.lng,
      };
    }

    const { userPosition } = location;
    var params = new URL(window.location).searchParams;
    let defaultLat = 53.4635776246581;
    let defaultLng = 9.98670961925282;
    if (params.get('lat') !== null && params.get('lng') !== null) {
      defaultLat = parseFloat(params.get('lat'));
      defaultLng = parseFloat(params.get('lng'));
    }

    return (
      <div
        className="map-container"
        style={{ height: '100%', width: '100%', margin: '0', padding: '0' }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{
            key: 'AIzaSyDb1klNSiqK5lic4w36n0EzCW-Fi1qpi28',
          }}
          defaultCenter={{ lat: defaultLat, lng: defaultLng }}
          center={center}
          defaultZoom={15}
          options={{ fullscreenControl: false }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map }) => {
            this.map = map;
          }}
        >
          {places.places &&
            places.places
              .filter(place => filter.includes(place.poi_category_id))
              .map(place => (
                <Marker
                  key={place.id}
                  lat={place.lat}
                  lng={place.long}
                  category={place.poi_category_id}
                  place={place}
                />
              ))}
          {userPosition && (
            <MyLocation lat={userPosition.lat} lng={userPosition.lng} />
          )}
        </GoogleMapReact>
        {options.visible && <OptionsOverlay />}
        <Menu />
        <Switch>
          <Route
            path={`/${I18n.locale}/lightbox`}
            render={props => <Lightbox {...props} />}
            exact
          />
          <Route
            path={`/${I18n.locale}/poi/:id`}
            render={props => (
              <ContentArea
                {...props}
                showPoi={true}
                showIntro={false}
                showContent={false}
              />
            )}
            exact
          />
          <Route
            path={`/${I18n.locale}/info`}
            render={props => (
              <ContentArea
                {...props}
                showPoi={false}
                showIntro={false}
                showContent={true}
              />
            )}
            exact
          />
          <Route
            path={`/${I18n.locale}/poi/:id/info`}
            render={props => (
              <ContentArea
                {...props}
                showPoi={true}
                showIntro={true}
                showContent={false}
              />
            )}
          />
          <Route
            path={`/${I18n.locale}/info/poi/:id`}
            render={props => (
              <ContentArea
                {...props}
                showPoi={true}
                showIntro={false}
                showContent={true}
              />
            )}
          />
        </Switch>
        <InfoOverlay visible={true} />
        <ImprintLink />
        <PrivacyLink />
        <LanguageSwitch />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  places: state.places,
  location: state.location,
  options: state.options,
  filter: state.categories.filter,
});

export default connect(mapStateToProps)(Map);
