import React from 'react';
import { StyledPrivacyLink } from './styled';

const PrivacyLink = () => (
  <StyledPrivacyLink href={`https://amh.de/datenschutz/`} target={`_blank`}>
    {I18n.t('privacy')}
  </StyledPrivacyLink>
);

export default PrivacyLink;
