import React from 'react';
import styled from 'styled-components';
import LanguageSwitch from './languageSwitch';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const ConsentArea = styled.div`
  background-color: #323232;
  height: 100vh;
  width: 100%;

  .logo {
    padding-left: 10%;
    margin: 2rem 0;
  }

  p {
    color: white;
    flex: 1 1 100%;
    max-width: 80%;
    margin: auto;
  }

  .back-button {
    position: absolute;
    top: 2.5rem;
    left: 1rem;
  }
`;

const Navigation = styled.div`
  display: flex;
  margin: 10% 10% 0 10%;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  flex-wrap: wrap;

  a {
    color: black;
    font-size: 1.5em;
    cursor: pointer;
    display: inline-block;
    background-color: white;
    text-decoration: none;
    padding: 0.5rem;
    border-radius: 7px;
    margin-top: 0.5rem;
    flex-shrink: 3;
    width: 17rem;
    text-align: center;
  }
`;

export const Consent = () => {
  const handleAccept = () => {
    document.cookie = 'consent=1';
    window.location.reload();
  };

  const history = useHistory();
  return (
    <ConsentArea className="page">
      <a className="back-button" onClick={() => history.goBack()}>
        Zurück
      </a>
      <img src="/images/amh_smh_sonderlogo.png" className="logo" />
      <LanguageSwitch />
      <p>{I18n.t('google_warning')}</p>
      <Navigation>
        <a
          href="https://amh.de/datenschutzhinweise/"
          target="_blank"
          rel="noreferrer"
        >
          {I18n.t('privacy')}
        </a>
        <a onClick={handleAccept}>{I18n.t('show_map')}</a>
      </Navigation>
    </ConsentArea>
  );
};
